
import { defineComponent, inject, nextTick, onMounted, PropType, provide, ref, toRefs } from 'vue'
import { MenuOptions, MenuItem, ContextMenuPositionData, MenuConstOptions } from './ContextMenuDefine'
import { getLeft, getTop } from './ContextMenuUtils'
import ContextMenuItem from './ContextMenuItem.vue'
import ContextMenuSperator from './ContextMenuSperator.vue'
import { VNodeRender } from './ContextMenuUtils'
import { GlobalHasSlot, GlobalRenderSlot } from './ContextMenu.vue'

//The internal info context for submenu
export interface SubMenuParentContext {
  zIndex: number;
  getMyPosition: () => ContextMenuPositionData;
  getParentWidth: () => number;
  addOpenedSubMenu: (closeFn: () => void) => void;
  closeOtherSubMenu: () => void;
  closeOtherSubMenuWithTimeOut: () => void;
  checkCloseOtherSubMenuTimeOut: () => boolean;
}

const fillPadding = 10; //padding for submenu position adjust

/**
 * Submenu container
 */
export default defineComponent({
  name: 'ContextSubMenu',
  components: {
    ContextMenuItem,
    ContextMenuSperator,
    VNodeRender,
  },
  props: {
    /**
     * Items from options
     */
    items: { 
      type: Object as PropType<Array<MenuItem>>,
      default: null
    },
    /**
     * Max width for this submenu
     */
    maxWidth: {
      type: [String, Number],
      default: 0,
    },
    /**
     * Min width for this submenu
     */
    minWidth: {
      type: [String, Number],
      default: 0,
    },
    /**
     * Specifies should submenu adjust it position 
     * when the menu exceeds the screen. The default is true
     */
    adjustPosition: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const parentContext = inject('menuContext') as SubMenuParentContext;
    const options = inject('globalOptions') as MenuOptions;
    const { zIndex, getMyPosition, getParentWidth } = parentContext;
    const { adjustPosition } = toRefs(props);

    const menu = ref<HTMLElement>();
    const scroll = ref<HTMLElement>();
    const openedSubMenuClose = [] as (() => void)[];

    let leaveTimeout = 0;

    //provide menuContext for child use
    const thisMenuContext = {
      zIndex: zIndex + 1,
      getMyPosition() {
        const pos = { x: 0, y: 0 } as ContextMenuPositionData;
        //计算子菜单的位置
        if(menu.value) 
          pos.x = menu.value.offsetWidth + (options.xOffset || 0);
        if (options.yOffset)
          pos.y = options.yOffset;
        return pos;
      },
      getParentWidth: () => menu.value?.offsetWidth,
      addOpenedSubMenu(closeFn: () => void) {
        openedSubMenuClose.push(closeFn);
      },
      closeOtherSubMenu() {
        openedSubMenuClose.forEach(k => k());
        openedSubMenuClose.splice(0, openedSubMenuClose.length);
      },
      checkCloseOtherSubMenuTimeOut() {
        if (leaveTimeout) {
          clearTimeout(leaveTimeout);
          leaveTimeout = 0;
          return true;
        }
        return false;
      },
      closeOtherSubMenuWithTimeOut() {
        leaveTimeout = setTimeout(() => {
          leaveTimeout = 0;
          this.closeOtherSubMenu();
        }, 200) as unknown as number; //Add a delay, the user will not hide the menu when moving too fast
      },
    } as SubMenuParentContext;
    provide('menuContext', thisMenuContext);

    const scrollValue = ref(0);
    const windowHeight = document.body.clientHeight;
    const windowWidth = document.body.clientWidth;
    const scrollHeight = ref(0);

    //Scroll the items
    function onScroll(down : boolean) {
      if (down)
        scrollValue.value = Math.max(scrollValue.value - 50, -scrollHeight.value);
      else 
        scrollValue.value = Math.min(scrollValue.value + 50, 0);
    }

    const overflow = ref(false);
    const position = ref({ x: 0, y: 0 } as ContextMenuPositionData)


    onMounted(() => {
      position.value = getMyPosition();
        
      nextTick(() => {
        //adjust submenu position
        if (adjustPosition.value && menu.value && scroll.value) {
          const menuEl = menu.value;
          scrollHeight.value = menuEl.offsetHeight - windowHeight + 20 /* Padding */;
          overflow.value = menu.value.offsetHeight > windowHeight;
          const absX = getLeft(menuEl), absY = getTop(menuEl);
          const xOv = absX + menuEl.offsetWidth - windowWidth;
          if (xOv > 0) //X overflow
            position.value.x -= (getParentWidth ? getParentWidth() : 0) + menuEl.offsetWidth - fillPadding; 
          if (overflow.value) {
            position.value.y = -(absY - fillPadding); //fill height
            //scroll.value.style.height = `${windowHeight}px`;
          } else {
            const yOv = absY + menuEl.offsetHeight - windowHeight;
            if (yOv > 0) 
              position.value.y -= yOv + fillPadding; //Y overflow
          }
        }
      });
    });

    const globalHasSlot = inject('globalHasSlot') as GlobalHasSlot;
    const globalRenderSlot = inject('globalRenderSlot') as GlobalRenderSlot;
    const globalTheme = inject('globalTheme') as string;

    return {
      menu,
      scroll,
      options,
      zIndex,
      constOptions: MenuConstOptions,
      scrollValue,
      overflow,
      position,
      windowHeight,
      windowWidth,
      fillPadding,
      scrollHeight,
      globalHasSlot,
      globalRenderSlot,
      globalTheme,
      onScroll,
    }
  }
})
